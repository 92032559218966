import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style.css"
import "../components/base.css"
import {useState} from "react";
import Comparison from "../components/comparison";
import {NorthEast} from "../components/material-icons";

const PricingPage = () => {
    const [plantype, setPlantype] = useState(0);

    return (
        <Layout>


            <section className={"section--lowspace section--row hcenter"}>
                <h1 className="supertitle">A plan for every industry</h1>
                <p className={"text-center"}>Hyperstack has affordable plans that will meet your needs.<br/>
                    Whether you need to send 500 or 5 million digital credentials, we got you covered.
                </p>

                <div className="switch" style={{width: "350px"}}>
                    <p style={{width: "50%"}} className={plantype === 0 ? "switch-selected" : null}
                       onClick={() => setPlantype(0)}>Monthly</p>
                    <p style={{width: "50%"}} className={plantype === 1 ? "switch-selected" : null}
                       onClick={() => setPlantype(1)}>Annually</p>
                </div>
                <div className={"slabs"}>
                    <div className={"slab"} style={{background: `#CCFFCC`}}>
                        <p className="slab-title">FREE</p>
                        <p>Essential for early stage</p>
                        <p className="slab-price">FREE</p>
                        <p>No Credit Card, Required</p>
                        <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                           style={{background: `#40D440`}}>Start Now</a>
                        <p className="slab-recipients">20 Recipients yearly</p>
                        <p><span className="bold">Access to</span></p>
                        <ul>
                            <li>Unlimited Certificates to Each Recipient</li>
                            <li>Access to Design Studio</li>
                            <li>Analytics and Insights</li>
                            <li>Email Customization</li>
                            <li>Canva Integration</li>
                            <li>Student Wallet</li>
                            <li>1 Team Member</li>
                            <li>Open Badge 2.0 Support</li>
                        </ul>
                    </div>
                    <div className={"slab"} style={{background: `#CCE9FF`}}>
                        <p className="slab-title">GROWTH L1</p>
                        <p>Access to all tools</p>
                        <p className="slab-price">{plantype === 0 ? "49 USD" : "480 USD"}</p>
                        {plantype === 0 ? <p>Save 20% on Annual Plan</p> :
                            <p className="no-gap">20% Discount (<span className="strike">600 USD</span>)</p>}
                        <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                           style={{background: `#4B92C8`}}>Start your free week</a>
                        <p className="slab-recipients">1000 Recipients yearly</p>
                        <p>All features in <span className="bold">Free,</span> plus</p>
                        <ul>
                            <li>Unlimited Certificates to Each Recipient</li>
                            <li>Custom Domain</li>
                            <li>Custom SMTP configuration</li>
                            <li>Branding and White-Labeling</li>
                            <li>API Access and Integration</li>
                            <li>Multi-Department Support</li>
                            <li>Feedback System</li>
                            <li>5 User Accounts</li>
                        </ul>
                    </div>
                    <div className={"slab"} style={{background: `#FFF1CC`}}>
                        <p className="slab-title">GROWTH L2</p>
                        <p>Access to all tools</p>
                        <p className="slab-price">{plantype === 0 ? "233 USD" : "2240 USD"}</p>
                        {plantype === 0 ? <p>Save 20% on Annual Plan</p> :
                            <p className="no-gap">20% Discount (<span className="strike">2800 USD</span>)</p>}
                        <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                           style={{background: `#BE9016`}}>Start your free week</a>
                        <p className="slab-recipients">Unlimited Recipients</p>
                        <p>All features in <span className="bold">Free,</span> plus</p>
                        <ul>
                            <li>Unlimited Certificates to Each Recipient</li>
                            <li>Custom Domain</li>
                            <li>Custom SMTP configuration</li>
                            <li>Branding and White-Labeling</li>
                            <li>API Access and Integration</li>
                            <li>Multi-Department Support</li>
                            <li>Feedback System</li>
                            <li>5 User Accounts</li>
                        </ul>
                    </div>

                    <div className={"slab"} style={{background: `#D8CCFF`}}>
                        <p className="slab-title">ENTERPRISE</p>
                        <p>Highly dynamic</p>
                        <p className="slab-price">Let's chat</p>
                        {/*<p>Billed Annually</p>*/}
                        <p className="slab-recipients">Unlimited Recipients</p>
                        <a className="slab-button" href="/contact-us?ref=plans" style={{background: `#6F3ED1`}}>Contact
                            Sales</a>
                        <p>Everything in <span className="bold">Growth,</span> plus</p>
                        <ul>
                            <li>Advanced access controls</li>
                            <li>Experiments & Signal reports</li>
                            <li>SSO & automated provisioning</li>
                            <li>Seasonal & usage-based pricing</li>
                            <li>Dedicated customer success</li>
                            <li>Growth Strategy Support</li>
                        </ul>
                    </div>
                </div>

                <div className="slab slab--horizontal">
                    <div>
                        <h2 className="mgb10">Build your own Growth Plan</h2>
                        <p>With our plan builder, you can customize your plan according to your organization size and need.
                            You will get access to all of our tools without any restrictions or hidden charges.</p>
                    </div>
                    <Link to="/plan-builder" className="slab-button">Build your Plan</Link>
                </div>

            </section>


            <br/>

            <section className="section--row hcenter">
                <h2 className="title">Compare Hyperstack Plans</h2>
                <p>7-day money-back guarantee. Start for free</p>
                <div className="comparison-container">
                    <table width="100%" border="0" className={"comparison"} cellPadding="30">
                        <tr>
                            <th className="align-left" width="30%"></th>
                            <th className="align-center" width="20%">
                                <p className="plan-title">Free</p>
                                <a className="plan-button" target="_blank"
                                   href="https://studio.thehyperstack.com/logon?ref=comparison">Start Now</a>
                            </th>
                            <th className="align-center" width="20%">
                                <p className="plan-title">Growth</p>
                                <Link className="plan-button" to={"/plan-builder?ref=comparison"}>Build your Plan</Link>
                            </th>
                            <th className="align-center" width="20%">
                                <p className="plan-title">Enterprise</p>
                                <a className="plan-button" href="/contact-us?ref=comparison">Contact Sales</a>
                            </th>
                        </tr>
                        <Comparison title="Unlimited Certificates to Each Recipients" p1={true} p2={true} p3={true}/>
                        <Comparison title="Certificate Design Studio" p1={true} p2={true} p3={true}/>
                        <Comparison title="Badge Design Studio" p1={true} p2={true} p3={true}/>
                        <Comparison title="Credential Grouping" p1={true} p2={true} p3={true}/>
                        <Comparison title="Advanced Analytics" p1={true} p2={true} p3={true}/>
                        <Comparison title="Marketing Analytics" p1={true} p2={true} p3={true}/>
                        <Comparison title="Canva Integration" p1={true} p2={true} p3={true}/>
                        <Comparison title="Student Wallet" p1={true} p2={true} p3={true}/>
                        <Comparison title="Teams" p1={false} p2={true} p3={true}/>
                        <Comparison title="API Access" p1={true} p2={true} p3={true}/>
                        <Comparison title="Open Badge V2" p1={true} p2={true} p3={true}/>
                        <Comparison title="Social Media Sharing" p1={true} p2={true} p3={true}/>
                        <Comparison title="Custom Email Templates" p1={false} p2={true} p3={true}/>
                        <Comparison title="Premium Branding" p1={false} p2={true} p3={true}/>
                        <Comparison title="Verified issuer icon" p1={false} p2={true} p3={true}/>
                        <Comparison title="Custom Domain" p1={false} p2={true} p3={true}/>
                        <Comparison title="Own Logo" p1={false} p2={true} p3={true}/>
                        <Comparison title="Email Customization" p1={false} p2={true} p3={true}/>
                        <Comparison title="Multi Department" p1={false} p2={true} p3={true}/>
                        <Comparison title="Issuer Verification Page" p1={false} p2={true} p3={true}/>
                        <Comparison title="Email support" p1={true} p2={true} p3={true}/>
                        <Comparison title="Chat support" p1={false} p2={true} p3={true}/>
                        <Comparison title="Apple/Google Wallet" p1={false} p2={false} p3={true}/>
                        <Comparison title="24/7 Phone support" p1={false} p2={false} p3={true}/>
                        <Comparison title="Custom Built Module" p1={false} p2={false} p3={true}/>
                        <Comparison title="Digilocker Integration" p1={false} p2={false} p3={true}/>
                        <Comparison title="Advanced access controls" p1={false} p2={false} p3={true}/>
                        <Comparison title="Experiments & Signal reports" p1={false} p2={false} p3={true}/>
                        <Comparison title="SSO & automated provisioning" p1={false} p2={false} p3={true}/>
                        <Comparison title="Seasonal & usage-based pricing" p1={false} p2={false} p3={true}/>
                        <Comparison title="Dedicated customer success" p1={false} p2={false} p3={true}/>
                        <Comparison title="Growth Strategy Support" p1={false} p2={false} p3={true}/>
                        <Comparison title="Onboarding training" p1={false} p2={false} p3={true}/>
                    </table>
                </div>
            </section>


        </Layout>
    )
}


export const Head = () => <Seo
    title="Hyperstack Pricing - A Plan for every Industries"
    description="Hyperstack has affordable plans based on your needs. Whether you want to send 500 credentials or 5 Million credentials we got you covered with our best plans"
/>

export default PricingPage
