import * as React from "react"
import {CheckCircle} from "./material-icons";

class Comparison extends React.Component {
    render() {
        return (
            <tr>
                <td className="align-left">
                    <p>{this.props.title}</p>
                </td>
                <td className="align-center">
                    <CheckCircle color={this.props.p1 ? "#485BFF" : "#CCCCCC"} size={"35px"}/>
                </td>
                <td className="align-center">
                    <CheckCircle color={this.props.p2 ? "#485BFF" : "#CCCCCC"} size={"35px"}/>
                </td>
                <td className="align-center">
                    <CheckCircle color={this.props.p3 ? "#485BFF" : "#CCCCCC"} size={"35px"}/>
                </td>
            </tr>
        )
    }
}

export default Comparison
